.tidal-embed-iframe {
  max-width: 560px;
  width: 100%;
  height: 96px;
  border-radius: 12px;
}
.tidal-border {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 560px;
  height: 96px;
  max-height: 96px;
  margin-top: 24px;
  padding: 0 7px;
  border-radius: 12px;
}
